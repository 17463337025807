import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';
import { Icon } from 'core/icon/icon';

export const StyledTopMenu = styled.div<{
  visible?: boolean;
  transparent?: boolean;
}>`
  ${({ theme, visible, transparent }) => css`
    height: ${visible ? '32px' : 0};
    overflow: ${visible ? 'unset' : 'hidden'};
    background-color: ${transparent
      ? theme.colors.translucentCream
      : theme.colors.cream};
    transition: height 0.1s ease-out;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 30px;
    align-items: center;
    padding-right: 40px;
    padding-left: ${theme.spacing.s3};
    user-select: none;
    justify-content: right;

    ${media.lessThan('sm')`
      padding-left: ${theme.spacing.s1};
      padding-right: ${theme.spacing.s1};
      display: none;
    `};
  `}
`;

export const StyledAccountChevron = styled(Icon)`
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledContent = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 10fr;
    padding: ${theme.spacing.s6} ${theme.spacing.s3};
    min-height: calc(
      100vh - ${theme.layout.header.height} - ${theme.layout.topMenuHeight}
    );

    & > *:first-child {
      ${media.lessThan('md')`
        display: none;
     `}
    }

    ${media.lessThan('md')`
        grid-template-columns: 1fr;
        padding: ${theme.spacing.s5} ${theme.spacing.s3};
     `}

    ${media.lessThan('sm')`
      padding: ${theme.spacing.s5} ${theme.spacing.s1};
      min-height: calc(
        100vh - ${theme.layout.header.heightSmaller} - ${theme.layout.topMenuHeight}
      );
    `}
  `}
`;

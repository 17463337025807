import React from 'react';
import { DropdownMenuProps } from 'core/dropdown/dropdown-menu.props';
import { StyledDropdownMenuContainer } from 'core/dropdown/dropdown.styles';

export const DropdownMenu = ({ width, children }: DropdownMenuProps) => {
  return (
    <StyledDropdownMenuContainer width={width}>
      {children}
    </StyledDropdownMenuContainer>
  );
};

import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledCheckoutHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const StyledHeader = styled.header<{ headerTransparent?: boolean }>`
  ${({ theme, headerTransparent }) => css`
    background-color: ${headerTransparent ? 'transparent' : theme.colors.white};
    width: 100%;
    z-index: 100;
    box-shadow: ${headerTransparent ? 'unset' : theme.shadow};
    position: fixed;
    height: 6.4rem;
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacing.s3};

    ${media.lessThan('sm')`
      padding: 0 ${theme.spacing.s1};
      height: 4.8rem;
    `}
  `}
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

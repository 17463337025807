import React, { createContext, Dispatch, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { QuickLink } from 'types/search.type';
import { SKU } from 'types/product.type';

export interface SearchState {
  searchString?: string;
  records: SKU[];
  quickLinks: QuickLink[];
  suggestedSearches: string[];
}

const initialState: SearchState = {
  searchString: '',
  records: [],
  quickLinks: [],
  suggestedSearches: [],
};

type Action =
  | { type: 'SET_RECORDS'; records: SKU[] }
  | {
      type: 'SET_QUICK_SEARCH_DATA';
      value: {
        quickLinks: QuickLink[];
        suggestedSearches: string[];
      };
    }
  | { type: 'SET_SEARCH_STRING'; value: string }
  | { type: 'RESET' };

function searchReducer(draft: SearchState, action: Action) {
  switch (action.type) {
    case 'SET_SEARCH_STRING':
      draft.searchString = action.value;
      return draft;
    case 'SET_RECORDS':
      draft.records = action.records;
      return draft;
    case 'SET_QUICK_SEARCH_DATA':
      draft.quickLinks = action.value.quickLinks;
      draft.suggestedSearches = action.value.suggestedSearches;
      return draft;
    case 'RESET':
      return initialState;
  }
}

const SearchStateContext = createContext<SearchState>(initialState);

const SearchDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const SearchProvider = (props: {
  value?: typeof initialState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    searchReducer,
    Object.assign({}, initialState, value),
  );

  useEffect(() => {
    dispatch({ type: 'SET_RECORDS', records: value?.records ?? [] });
  }, [value?.records]);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

export function useSearchState(): SearchState {
  const context = useContext(SearchStateContext);

  if (context === undefined) {
    throw new Error(`useSearch must be used inside a Search Provider`);
  }

  return context;
}

export function useSearchDispatch() {
  const context = useContext(SearchDispatchContext);

  if (context === undefined) {
    throw new Error(`useSearch must be used inside a SearchProvider`);
  }

  return context;
}

export const useSearch = (): [SearchState, Dispatch<Action>] => [
  useSearchState(),
  useSearchDispatch(),
];

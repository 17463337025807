import React from 'react';
import { NotificationsManager } from 'layout/notifications-manager';
import { StyledTopNotificationsContainer } from 'layout/layout/shop-layout.styles';
import { Icon } from 'core/icon';
import { Button } from 'core/button';
import {
  StyledCheckoutHeader,
  StyledHeader,
  StyledHeaderContent,
} from 'layout/headers/checkout-header/checkout-header.styles';
import Link from 'next/link';
import { StackedContainer } from 'layout/stacked-container';
import { useAuth } from 'context/auth.context';
import { useSignInModal } from 'modals/sign-in';

export const CheckoutHeader = () => {
  const [{ authenticated }] = useAuth();
  const { showModal } = useSignInModal({
    source: 'Checkout',
    signUpEventName: 'Sign Up - Checkout Header',
  });

  return (
    <StyledCheckoutHeader>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledHeader headerTransparent={false}>
        <StyledHeaderContent>
          <Link href="/shop">
            <a>
              <Icon
                name="logo"
                color={'black'}
                width={{ lg: '12rem', sm: '9rem' }}
                height={{ lg: '3rem', sm: '2rem' }}
                cursor="pointer"
              />
            </a>
          </Link>

          <StackedContainer
            flow={'column'}
            padding={'unset'}
            gap="s3"
            alignContent={'center'}
            alignItems={'center'}>
            {!authenticated && (
              <Button
                tx="checkout:login"
                textColor="grey"
                textStyle="regular"
                variant="action"
                icon={<Icon name="user" height="2.6rem" />}
                height="2.6rem"
                onClick={showModal}
              />
            )}

            <Button
              tx="checkout:help"
              textColor="grey"
              textStyle="regular"
              variant="action"
              icon={<Icon name="nav-help" height="2.6rem" />}
              linkHref="mailto:support@gantri.com"
              height="2.6rem"
            />
          </StackedContainer>
        </StyledHeaderContent>
      </StyledHeader>
    </StyledCheckoutHeader>
  );
};

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { SideMenuProps } from 'layout/side-menu/side-menu.props';
import { SideMenuPresets } from 'layout/side-menu/side-menu.presets';
import {
  StyledNewTag,
  StyledSearchContainer,
  StyledSideMenu,
  StyledSideMenuBackdrop,
  StyledSideMenuContainer,
  StyledSideMenuLink,
} from 'layout/side-menu/side-menu.styles';
import { useSpring } from 'react-spring';
import { MenuItemProps } from 'layout/menu/menu.props';
import { Typography } from 'core/typography';
import { SideMenuItem } from 'layout/side-menu/side-menu-item';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useAuth } from 'context/auth.context';
import { useSignInModal } from 'modals/sign-in';
import { useMediaLarge, useMounted } from 'utils/hooks';
import { useLayout } from 'context/layout.context';
import { trackAnalyticsEvent } from 'utils/analytics';
import { environment } from 'config/environment';
import { useSignOut } from 'modals/sign-in/sign-in.hooks';
import { Icon } from 'core/icon';
import { TypographyWithIcon } from 'core/typography-with-icon';

const siteItems: MenuItemProps[] = [
  {
    tx: 'shopMenu.shopTable',
    to: '/shop/[category]',
    as: '/shop/table-lights',
  },
  {
    tx: 'shopMenu.shopWall',
    to: '/shop/[category]',
    as: '/shop/wall-lights',
  },
  {
    tx: 'shopMenu.shopFloor',
    to: '/shop/[category]',
    as: '/shop/floor-lights',
  },
  { tx: 'shopMenu.designers', to: '/designers' },
  { tx: 'shopMenu.giftCards', to: '/gift-cards', isNew: true },
];

const accountItems: MenuItemProps[] = [
  {
    tx: 'shopMenu.about',
    submenu: [
      { tx: 'shopMenu.ourStory', to: '/about' },
      { tx: 'shopMenu.materials', to: '/materials' },
      { tx: 'shopMenu.factory', to: '/factory' },
      { tx: 'shopMenu.create', to: environment.CREATE_HUB_URL },
      { tx: 'shopMenu.press', to: '/press' },
    ],
  },
  { tx: 'shopMenu.earn20', to: '/refer' },
  {
    tx: 'shopMenu.help',
    textVariant: 'p2',
    to: 'https://support.gantri.com/',
    external: true,
  },
];

export const SideMenu = (props: SideMenuProps) => {
  const { visible, onMenuClose } = Object.assign({}, SideMenuPresets, props);
  const [subMenuItems, setSubMenuItems] = useState<MenuItemProps[]>(
    accountItems,
  );
  const menuClose = useCallback(onMenuClose, []);
  const { hideSideMenu } = useLayout();
  const mounted = useMounted();
  const isLarge = useMediaLarge();

  const { showModal } = useSignInModal();

  const router = useRouter();
  const [{ authenticated, user }] = useAuth();
  const { signOut } = useSignOut();

  useEffect(() => {
    menuClose();
  }, [router.pathname]);

  const spring = useSpring({
    width: visible ? 260 : 0,
    opacity: visible ? 1 : 0,
    config: { duration: 200 },
  });

  useEffect(() => {
    let temporal;

    if (authenticated && !!user?.firstName) {
      temporal = [
        ...(user.isDesigner
          ? [
              {
                tx: 'shopMenu.createHub',
                color: 'primary',
                to: `${environment.CREATE_HUB_URL}/dashboard`,
                external: true,
              },
              {
                tx: 'shopMenu.storefront',
                to: `/${user?.designerInfo?.profileLink}`,
              },
            ]
          : []),
        ...accountItems,
        {
          text: user.firstName,
          textVariant: 'p2',
          submenu: [
            { tx: 'shopMenu.settings', to: '/account/settings' },
            { tx: 'shopMenu.addresses', to: '/account/addresses' },
            { tx: 'shopMenu.payments', to: '/account/payments' },
            { tx: 'shopMenu.wishlist', to: '/account/wishlist' },
            { tx: 'shopMenu.orders', to: '/account/orders' },
          ],
        },
        {
          tx: 'shopMenu.logout',
          textVariant: 'p2',
          onClick: () => {
            signOut();
            trackAnalyticsEvent('Menu Log Out');
          },
        },
      ];
    } else {
      temporal = [
        ...accountItems,
        {
          tx: 'shopMenu.account',
          textVariant: 'p2',
          onClick: () => {
            showModal();
            trackAnalyticsEvent('Menu Auth');
          },
        },
      ];
    }

    setSubMenuItems(temporal);
  }, [authenticated]);

  const renderMenuItem = (
    item: MenuItemProps,
    fontSize?: string,
    lineHeight?: string,
  ): ReactNode => (
    <Link
      href={item.to ?? '/'}
      as={item.as}
      key={item.tx ?? item.text}
      prefetch={!item.external}>
      <a target={item.external ? '_blank' : '_self'}>
        <StyledSideMenuLink
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }
            hideSideMenu();
          }}>
          <Typography
            fontSize={fontSize || '1.6rem'}
            tx={item.tx}
            text={item.text}
            color={item.color ?? 'grey'}
            lineHeight={lineHeight ?? '4rem'}
          />

          {item.isNew && (
            <StyledNewTag
              tx="shopMenu.new"
              color="primary"
              variant="p3"
              textStyle="bold"
            />
          )}
        </StyledSideMenuLink>
      </a>
    </Link>
  );

  if (!mounted || isLarge) {
    return null;
  }

  return (
    <>
      <StyledSideMenuContainer style={spring}>
        <StyledSideMenu>
          {siteItems.map((item: MenuItemProps) =>
            item.submenu && visible ? (
              <SideMenuItem item={item} key={item.tx ?? item.text} />
            ) : (
              renderMenuItem(item)
            ),
          )}
        </StyledSideMenu>

        <StyledSideMenu>
          {subMenuItems.map((item: MenuItemProps) =>
            item.submenu && visible ? (
              <SideMenuItem
                item={item}
                lineHeight="4rem"
                key={item.tx ?? item.text}
              />
            ) : (
              renderMenuItem(item, '1.4rem', '4rem')
            ),
          )}

          <StyledSearchContainer>
            <TypographyWithIcon
              variant="link"
              href="/search"
              text="Search"
              textStyle="regular"
              color="grey"
              icon={<Icon name="search" cursor="pointer" top="-5px" />}
            />
          </StyledSearchContainer>
        </StyledSideMenu>
      </StyledSideMenuContainer>

      {visible && <StyledSideMenuBackdrop onClick={menuClose} />}
    </>
  );
};

import React, { useCallback } from 'react';
import {
  StyledShopHeader,
  StyledShopHeaderContainer,
} from './designer-header.styles';
import { Menu } from 'layout/menu';
import { useSignInModal } from 'modals/sign-in';
import { TopMenu } from 'layout/top-menu';
import { useAuth } from 'context/auth.context';
import { useCart } from 'context/cart.context';
import { useLayout } from 'context/layout.context';
import { useSignOut } from 'modals/sign-in/sign-in.hooks';
import { DesignerEditHeader } from 'layout/headers/designer-edit-header';
import { useRouter } from 'next/router';

export const DesignerHeader = () => {
  const {
    headerVisibilityLocked,
    headerVisible,
    sideMenuVisible,
    toggleSideMenuVisibility,
  } = useLayout();

  const [{ authenticated, user }] = useAuth();
  const [{ records, wishlist }] = useCart();
  const { signOut } = useSignOut();
  const { showModal } = useSignInModal();
  const { query } = useRouter();

  const isHeaderVisible =
    headerVisible || sideMenuVisible || headerVisibilityLocked;

  const onSignUpModalShow = useCallback((): void => {
    if (!authenticated) {
      showModal();
    }
  }, [authenticated]);

  const designerBarVisible =
    user?.isAdmin ||
    (user?.isDesigner &&
      user?.designerInfo?.profileLink === query.designerProfileLink);

  return (
    <StyledShopHeaderContainer>
      <StyledShopHeader headerTransparent={!isHeaderVisible}>
        <TopMenu
          color={isHeaderVisible ? 'grey' : 'white'}
          transparent={!isHeaderVisible}
        />

        <Menu
          isDesigner={!!user?.designerInfo}
          color={isHeaderVisible ? 'grey' : 'white'}
          cartItemsCount={records.length}
          closeIconActive={sideMenuVisible}
          wishlistCount={wishlist.length ?? 0}
          authenticated={authenticated}
          designerProfileLink={user?.designerInfo?.profileLink}
          onMenuButtonClick={toggleSideMenuVisibility}
          onAccountClick={onSignUpModalShow}
          onLogout={signOut}
        />
      </StyledShopHeader>

      {designerBarVisible && <DesignerEditHeader />}
    </StyledShopHeaderContainer>
  );
};

import { DropdownProps } from './dropdown.props';
import { DropdownPresets } from './dropdown.presets';
import React, { useRef, useState } from 'react';
import {
  StyledDropdown,
  StyledDropdownMenu,
} from 'core/dropdown/dropdown.styles';
import { useClickAway } from 'react-use';

export const Dropdown = (props: DropdownProps) => {
  const { canShowDropdown, menu, children } = Object.assign(
    {},
    DropdownPresets,
    props,
  );
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);

  useClickAway(containerRef, () => {
    setExpanded(false);
  });

  const toggleExpanded = () => {
    if (canShowDropdown) {
      setExpanded(!expanded);
    }
  };

  return (
    <StyledDropdown onClick={toggleExpanded} ref={containerRef}>
      {children}
      {expanded && <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
    </StyledDropdown>
  );
};

import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import {
  StyledBody,
  StyledContentContainer,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/shop-layout.styles';
import { ShopHeader } from 'layout/headers';
import { ShopFooter } from 'layout/footer';
import { SideMenu } from 'layout/side-menu';
import { useRouter } from 'next/router';
import { useAuthHandler, useFetchCart } from 'src/layout/layout/hooks';
import { useLayout } from 'context/layout.context';
import { useAuth } from 'context/auth.context';
import { useUpdateEffect } from 'react-use';
import { LoadingOverlay } from 'layout/loading-overlay';
import { AccountMenu } from 'layout/account-menu';
import { StyledContent } from 'layout/layout/account-layout.styles';
import { NotificationsManager } from 'layout/notifications-manager';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';

export const AccountLayout = (props: ShopLayoutProps) => {
  const { sideMenuVisible, hideSideMenu } = useLayout();
  const [{ authenticated }] = useAuth();
  const { push: pushRoute } = useRouter();

  useAuthHandler({ requiresAuthorization: true });
  useFetchCart();

  useLayoutSharedBehavior();

  useUpdateEffect(() => {
    if (!authenticated) {
      pushRoute('/');
    }
  }, [authenticated]);

  if (!authenticated) {
    return <LoadingOverlay />;
  }

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledTopContainer>
        <ShopHeader />
      </StyledTopContainer>

      <StyledContentContainer padded={true}>
        <StyledContent>
          <AccountMenu />
          {props.children}
        </StyledContent>
        <ShopFooter />
      </StyledContentContainer>
      <SideMenu visible={sideMenuVisible} onMenuClose={hideSideMenu} />
    </StyledBody>
  );
};

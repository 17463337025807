import { Icon } from 'core/icon';
import { Button } from 'core/button';
import React from 'react';
import { useRouter } from 'next/router';
import { AccountMenuItemProps } from 'layout/account-menu/sections/account-menu-item/account-menu-item.props';
import { IconType } from 'core/icon/icon.type';

export const AccountMenuItem = ({
  text,
  linkHref,
  iconName,
  icon,
}: AccountMenuItemProps) => {
  const { asPath } = useRouter();

  return (
    <Button
      variant="action"
      text={text}
      color={asPath.includes(linkHref) ? 'primary' : 'grey'}
      textStyle="regular"
      linkHref={linkHref}
      icon={
        icon ?? (
          <Icon
            name={iconName as IconType}
            width="1.8rem"
            color={asPath.includes(linkHref) ? 'primary' : 'black'}
          />
        )
      }
    />
  );
};

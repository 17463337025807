import React, { useState } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { StyledInfoIcon } from 'modals/feedback/feedback.styles';
import { useModalClose } from 'context/modal.context';
import { Grid } from 'layout/grid';
import { SURVEY_URL } from 'modals/feedback/feedback.constants';

export const FeedbackModal = () => {
  const [surveyTaken, setSurveyTaken] = useState(false);
  const { closeLastOpened } = useModalClose();

  const initialContent = (
    <>
      <Typography tx="feedbackModal:title" variant="h3" align="center" />
      <Typography tx="feedbackModal:description" color="grey" align="center" />

      <Grid
        columns="repeat(2, 1fr)"
        padding="unset"
        marginTop="s1"
        width="100%"
        gap="s1">
        <Button
          variant="secondary"
          tx="feedbackModal:noButton"
          width="unset"
          onClick={closeLastOpened}
        />

        <Button
          tx="feedbackModal:yesBtn"
          linkHref={SURVEY_URL}
          linkTarget="_blank"
          width="unset"
          onClick={() => setSurveyTaken(true)}
        />
      </Grid>
    </>
  );

  const surveyTakenContent = (
    <>
      <StyledInfoIcon>
        <Icon name="check" size="2.5rem" color="blue" />
      </StyledInfoIcon>

      <Typography
        tx="feedbackModal:thankYouTitle"
        variant="h3"
        align="center"
      />

      <Typography
        tx="feedbackModal:thankYouDescription"
        color="grey"
        align="center"
      />

      <Typography
        tx="feedbackModal:feedbackSurvey"
        align="center"
        variant="link"
        href={SURVEY_URL}
        target="_blank"
        passHref={true}
        prefetch={false}
      />

      <Button
        tx="feedbackModal:doneButton"
        width="100%"
        onClick={closeLastOpened}
      />
    </>
  );

  return (
    <StackedContainer
      gap="s2"
      padding={{ lg: 's5', sm: 's3' }}
      justifyItems="center"
      justifyContent="center">
      {surveyTaken ? surveyTakenContent : initialContent}
    </StackedContainer>
  );
};

import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';
import { ColorProperty } from 'csstype';
import { StyledInputContainer } from 'core/text-input/text-input.styles';
import { animated } from '@react-spring/web';

export const StyledShopHeaderContainer = styled.header<{
  headerTransparent?: boolean;
}>`
  ${({ theme, headerTransparent }) => css`
    background-color: ${headerTransparent ? 'transparent' : theme.colors.white};
    width: 100%;
    z-index: 100;
    position: fixed;
  `}
`;

export const StyledShopHeader = styled.div<{ headerTransparent?: boolean }>`
  ${({ theme, headerTransparent }) => css`
    background-color: ${headerTransparent ? 'transparent' : theme.colors.white};
    width: 100%;
    z-index: 1;
    box-shadow: ${headerTransparent ? 'unset' : theme.shadow};
    position: relative;
  `}
`;

export const StyledEditHeader = styled.div`
  ${({ theme }) => css`
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.s1} ${theme.spacing.s3};
    height: 4.8rem;

    ${media.lessThan('sm')`
      padding: ${theme.spacing.s1} ${theme.spacing.s1};
    `}
  `}
`;

export const StyledColorPreview = styled.div<{ color: ColorProperty }>`
  ${({ color, theme }) => css`
    width: 2.4rem;
    height: 2.4rem;
    background-color: ${color};
    border-radius: 50%;
    border: 1px solid ${theme.colors.lightGrey};
  `}
`;

export const StyledColorSelectorsContainer = styled(animated.div)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: ${theme.spacing.s5};
    transition: ${theme.transitions.base};

    ${media.lessThan('md')`
      grid-template-columns: 1fr;
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-left: ${theme.spacing.s1};
      border-top: 1px solid ${theme.colors.lightGrey};
      row-gap: ${theme.spacing.s2};
      height: 11.5rem;
    `}
  `}
`;

export const StyledColorSelector = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 8px;
    position: relative;

    ${StyledInputContainer} {
      width: 6rem;

      input {
        padding: 0;
      }
    }
  `}
`;

export const StyledColorInputContainer = styled.div`
  width: 6rem;
  position: relative;
`;

export const StyledColorPopup = styled.div`
  position: absolute;
  right: 0;
  top: 3.5rem;

  ${media.lessThan('md')`
    right: unset;
    left: 0;
    z-index: 1;
  `}
`;

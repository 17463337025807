import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledBody = styled.div`
  display: grid;
`;

export const StyledCheckoutBody = styled.div`
  ${({ theme }) => css`
    display: grid;
    background-color: ${theme.colors.cream};
    min-height: 100vh;
  `}
`;

export const StyledTopNotificationsContainer = styled.div`
  position: relative;
  z-index: 1000011;
`;

export const StyledTopContainer = styled.div`
  position: relative;
  z-index: 10;
`;

export const StyledContentContainer = styled.div<{
  padded?: boolean;
  topMenuHeight?: string;
}>`
  ${({ padded, theme, topMenuHeight = '3.2rem' }) => css`
    position: relative;
    z-index: 0;
    padding-top: ${padded
      ? `calc(${theme.layout.header.height} + ${topMenuHeight})`
      : 'unset'};

    ${media.lessThan('md')`
      padding-top: ${
        padded
          ? `calc(${theme.layout.header.height} + ${topMenuHeight})`
          : 'unset'
      };
    `}

    ${media.lessThan('sm')`
      padding-top: ${padded ? `${theme.layout.header.heightSmaller}` : 'unset'};
    `}
  `}
`;

export const StyledContent = styled.div`
  display: inherit;
  position: relative;
`;

export const StyledObservableDiv = styled.div`
  width: 100%;
  height: 3px;
  position: absolute;
  top: 1px;
  z-index: 100000;
`;

import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';
import { TextInput } from 'core/text-input';
import { Typography } from 'core/typography';

export const StyledShopHeader = styled.header<{ headerTransparent?: boolean }>`
  ${({ theme, headerTransparent }) => css`
    background-color: ${headerTransparent ? 'transparent' : theme.colors.white};
    width: 100%;
    z-index: 100;
    box-shadow: ${headerTransparent ? 'unset' : theme.shadow};
    position: fixed;
  `}
`;

export const StyledSearchModalContainer = styled.div`
  width: 100%;
`;

export const StyledSearchModalHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 6.4rem;
    padding: 0 ${theme.spacing.s3};
    border-bottom: 1px solid ${theme.colors.lightGrey};

    ${media.lessThan('md')`
      border-bottom: unset;
    `}

    ${media.lessThan('sm')`
      padding: 0 ${theme.spacing.s1};
      bottom: ${theme.spacing.s1};
      height: 4.8rem;
    `}
  `}
`;

export const StyledSearchInputContainer = styled.div`
  position: relative;
  top: 5px;
`;

export const StyledSearchInput = styled(TextInput)`
  ${({ theme }) => css`
    color: ${theme.colors.grey};
    border: unset;
  `}
`;

export const StyledSearchContent = styled.div`
  width: 45rem;

  ${media.lessThan('md')`
    width: 100%;
  `}
`;

export const StyledSearchDropdown = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: calc(${theme.layout.header.height} + ${theme.layout.topMenuHeight});
    width: 100%;
    z-index: 1;
    padding: ${theme.spacing.s3} 25%;
    display: grid;
    row-gap: ${theme.spacing.s3};
    box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.12);
    user-select: none;
    background-color: ${theme.colors.white};

    ${media.lessThan('md')`
       padding: ${theme.spacing.s3};
       row-gap: ${theme.spacing.s2};
    `}

    ${media.lessThan('sm')`
       top: calc(${theme.layout.header.heightSmaller} + ${theme.layout.topMenuHeightSmaller});
       padding: ${theme.spacing.s2};
    `}
  `}
`;

export const StyledSearchDropdownDivider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGrey};
  `}
`;

export const StyledSearchLink = styled(Typography)`
  ${({ theme }) => css`
    &:hover {
      color: ${theme.colors.primary};
      text-decoration: none;
      cursor: pointer;
    }
  `}
`;

import React, { useCallback, useEffect, useRef } from 'react';
import { useModal } from 'context/modal.context';
import { useCookies } from 'react-cookie';
import { FeedbackModal } from 'modals/feedback/feedback';
import { useRouter } from 'next/router';
import {
  FEEDBACK_POPUP_DISCARDED,
  PAGES_VISITED_BEFORE_DISPLAY,
} from 'modals/feedback/feedback.constants';

export const useFeedbackModal = () => {
  const router = useRouter();
  const ref = useRef<number>(0);

  const [cookies, setCookies] = useCookies([FEEDBACK_POPUP_DISCARDED]);

  const onClose = useCallback(() => {
    setCookies(FEEDBACK_POPUP_DISCARDED, true);
  }, []);

  const { showModal } = useModal({
    template: <FeedbackModal />,
    width: '46rem',
    onClose,
  });

  useEffect(() => {
    if (!cookies[FEEDBACK_POPUP_DISCARDED]) {
      ref.current += 1;

      if (ref.current === PAGES_VISITED_BEFORE_DISPLAY) {
        showModal();
      }
    }
  }, [router.asPath]);
};

import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import { ShopLayoutPresets } from 'layout/layout/shop-layout.presets';
import {
  StyledBody,
  StyledContent,
  StyledContentContainer,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/search-layout.styles';
import { useAuthHandler } from 'src/layout/layout/hooks';
import { NotificationsManager } from 'layout/notifications-manager';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';
import { useRouter } from 'next/router';
import { ShopFooter } from 'layout/footer';
import { SearchHeader } from 'layout/headers/search-header';
import { ShippingBannerPrimary } from 'core/shipping-banner-primary';
import { useSearch } from 'context/search.context';

export const SearchLayout = (props: ShopLayoutProps) => {
  const { children } = Object.assign({}, ShopLayoutPresets, props);
  const { asPath } = useRouter();
  const [{ records }] = useSearch();

  useAuthHandler({
    shouldFetchCartInfo: asPath.includes('cart'),
  });

  useLayoutSharedBehavior();

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledTopContainer>
        <SearchHeader />
      </StyledTopContainer>

      <StyledContentContainer>
        <StyledContent>{children}</StyledContent>

        {!!records.length && (
          <>
            <ShippingBannerPrimary />
            <ShopFooter />
          </>
        )}
      </StyledContentContainer>
    </StyledBody>
  );
};

import styled, { css } from 'styled-components/macro';

export const StyledShopHeader = styled.header<{ headerTransparent?: boolean }>`
  ${({ theme, headerTransparent }) => css`
    background-color: ${headerTransparent ? 'transparent' : theme.colors.white};
    width: 100%;
    z-index: 100;
    box-shadow: ${headerTransparent ? 'unset' : theme.shadow};
    position: fixed;
  `}
`;

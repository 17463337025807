import styled, { css } from 'styled-components/macro';

export const StyledDropdown = styled.div`
  cursor: pointer;
  position: relative;
`;

export const StyledDropdownMenu = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.lightGrey};
    min-width: 16rem;
    max-height: 50rem;
    border-radius: 0.3rem;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: calc(100% + 1rem);
    z-index: 1;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15);
  `}
`;

export const StyledDropdownItem = styled.li`
  ${({ theme }) => css`
    list-style: none;
    padding: 0.8rem 2rem;
    cursor: pointer;
    transition: ${theme.transitions.base};
    user-select: none;

    &:hover {
      background-color: ${theme.colors.cream};
    }
  `}
`;

export const StyledDropdownLinkItem = styled.div`
  ${({ theme }) => css`
    list-style: none;
    padding: 0.8rem 2rem;
    cursor: pointer;
    transition: ${theme.transitions.base};
    user-select: none;

    &:hover {
      background-color: ${theme.colors.lightGrey};
    }
  `}
`;

export const StyledDropdownDivider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background: ${theme.colors.lightGrey};
    margin-top: 1rem;
    margin-bottom: 1rem;
  `}
`;

export const StyledDesignerBadgeContainer = styled.div`
  margin-left: 10px;
  top: -4px;
  position: relative;
`;

export const StyledDropdownMenuContainer = styled.div<{ width?: string }>`
  ${({ width }) => css`
    width: ${width};
    padding: 1rem 0;
    user-select: none;
  `}
`;

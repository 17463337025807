import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledFloatingLogo = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.s3};
    height: ${theme.layout.header.height};
    display: flex;
    align-items: center;
    z-index: 1;

    ${media.lessThan('md')`
      height: ${theme.layout.header.heightSmaller};
      pointer-events: none;
      
      & > * {
        display: none;
      }
    `}
  `}
`;

import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledFooter = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 4fr 1fr;
    padding: ${theme.spacing.s6} 0;
    transition: all 0.3s ease;

    ${media.lessThan('md')`
      padding: ${theme.spacing.s5} ${theme.grid.gutter};
      grid-template-columns: 1fr;
      grid-gap: ${theme.spacing.s4};
    `}

    ${media.lessThan('sm')`
       grid-gap: ${theme.spacing.s3};
       padding: ${theme.spacing.s5} ${theme.grid.gutterSmaller};
    `}
  `}
`;

const StyledFooterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledFooterSectionLeft = styled(StyledFooterSection)`
  grid-column: 2;

  ${media.lessThan('md')`
     grid-row: 1;
     grid-column: 1;
  `}
`;

export const StyledFooterSectionRight = styled(StyledFooterSection)`
  grid-column: 4;

  ${media.lessThan('md')`
     grid-row: 2;
     grid-column: 1;
  `}
`;

export const StyledSocialButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

export const StyledPolicyLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 0.5rem;

    & > a:first-child {
      margin-right: 1.6rem;
      position: relative;

      &:after {
        content: '|';
        position: absolute;
        right: -1rem;
        top: 0;
        color: ${theme.colors.grey};
        font-size: 1.2rem;
      }
    }
  `}
`;

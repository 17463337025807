import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import { ShopLayoutPresets } from 'layout/layout/shop-layout.presets';
import { useIntersectionObserver } from 'utils/hooks';
import {
  StyledBody,
  StyledContent,
  StyledObservableDiv,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/shop-layout.styles';
import { DesignerHeader } from 'layout/headers';
import { ShopFooter } from 'layout/footer';
import { SideMenu } from 'layout/side-menu';
import { useRouter } from 'next/router';
import { useAuthHandler, useFetchCart } from 'src/layout/layout/hooks';
import { useLayout } from 'context/layout.context';
import { useFeedbackModal } from 'modals/feedback';
import { NotificationsManager } from 'layout/notifications-manager';
import { StyledContentContainer } from 'layout/layout/designer-layout.styles';
import { useAuth } from 'context/auth.context';
import { useAsync } from 'react-use';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';

const fileLabel = 'components/Layout';

export const DesignerLayout = (props: ShopLayoutProps) => {
  const { children } = Object.assign({}, ShopLayoutPresets, props);
  const { footerVisible } = useLayout();
  const {
    headerVisibilityLocked,
    sideMenuVisible,
    setHeaderVisibility,
    hideSideMenu,
  } = useLayout();
  const { asPath, query, push: routerPush } = useRouter();
  const [{ user, authenticated, status }] = useAuth();

  useAuthHandler({
    shouldFetchCartInfo: asPath.includes('cart'),
  });
  useFetchCart();
  useFeedbackModal();

  useLayoutSharedBehavior();

  useAsync(async () => {
    if (status !== 'FETCHING' && !authenticated) {
      await routerPush(
        '/[designerProfileLink]',
        `/${query.designerProfileLink}`,
      );
    }
  }, [authenticated, status]);

  const { observerRef } = useIntersectionObserver({
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      setHeaderVisibility(!isIntersecting);
    },
  });

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledTopContainer>
        <DesignerHeader />
      </StyledTopContainer>

      <StyledContentContainer
        padded={headerVisibilityLocked}
        editBarVisible={
          user?.isAdmin ||
          (user?.isDesigner &&
            user?.designerInfo?.profileLink === query.designerProfileLink)
        }>
        <StyledObservableDiv ref={observerRef} />

        <StyledContent>{children}</StyledContent>

        {footerVisible && <ShopFooter />}
      </StyledContentContainer>
      <SideMenu visible={sideMenuVisible} onMenuClose={hideSideMenu} />
    </StyledBody>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  StyledSearchDropdown,
  StyledSearchDropdownDivider,
  StyledSearchInput,
  StyledSearchInputContainer,
  StyledSearchLink,
  StyledShopHeader,
} from 'src/layout/headers/search-header/search-header.styles';
import { Cell } from 'layout/cell';
import Link from 'next/link';
import { FlexContainer } from 'layout/flex-container';
import { Icon } from 'core/icon';
import { Grid } from 'layout/grid';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import { trackAnalyticsEvent } from 'utils/analytics';
import { useSearch } from 'context/search.context';
import { useAsync } from 'react-use';
import { api } from 'services/api';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Thumbnail } from 'core/thumbnail';
import { convertSearchProductsToCartProducts } from 'modules/shop/product/product.adapter';
import { useNotification } from 'context/notification.context';
import { TopMenu } from 'layout/top-menu';

export const SearchHeader = () => {
  const {
    layout: {
      header: { height: headerHeight, heightSmaller: headerHeightSmaller },
    },
  } = useTheme();

  const { query, push } = useRouter();
  const { showPreloader, hidePreloader } = useNotification();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [initialized, setInitialized] = useState(false);

  const inputRef = useRef(null);

  const [
    { searchString, quickLinks, suggestedSearches },
    searchDispatch,
  ] = useSearch();

  const setSearchStringValue = debounce(async (value: string) => {
    searchDispatch({
      type: 'SET_SEARCH_STRING',
      value,
    });

    await push(`/search/?query=${value}`, undefined, { shallow: true });

    trackAnalyticsEvent('Products Searched', { query: value });
  }, 300);

  useEffect(() => {
    return () => {
      searchDispatch({
        type: 'RESET',
      });
    };
  }, []);

  const onSearchClear = useCallback(() => {
    setSearchValue('');
    setSearchStringValue('');
  }, [searchValue, setSearchStringValue, setSearchValue]);

  useAsync(async () => {
    setSearchValue(searchString);

    if (searchString) {
      const {
        data: { success, quickLinks, suggestedSearch },
      } = await api.product.searchAutocomplete(searchString);

      if (success) {
        searchDispatch({
          type: 'SET_QUICK_SEARCH_DATA',
          value: {
            quickLinks,
            suggestedSearches: suggestedSearch,
          },
        });
      }
    } else {
      searchDispatch({
        type: 'SET_QUICK_SEARCH_DATA',
        value: {
          quickLinks: [],
          suggestedSearches: [],
        },
      });

      searchDispatch({
        type: 'SET_RECORDS',
        records: [],
      });
    }
  }, [searchString]);

  const onSearchInputBlur = useCallback(() => {
    setTimeout(() => {
      setDropdownVisible(false);
    }, 500);
  }, [dropdownVisible]);

  const executeSearch = useCallback(async (value: string) => {
    inputRef.current.blur();
    setDropdownVisible(false);

    showPreloader();

    const {
      data: { success, result },
    } = await api.product.search(value);

    hidePreloader();

    searchDispatch({
      type: 'SET_RECORDS',
      records: success ? convertSearchProductsToCartProducts(result) : [],
    });
  }, []);

  const onSearchFocus = () => {
    setInitialized(true);
    setDropdownVisible(true);
  };

  const onSuggestedSearchSelected = useCallback(
    async (name: string) => {
      setSearchValue(name);
      setSearchStringValue(name);
      await executeSearch(name);
    },
    [setDropdownVisible, executeSearch],
  );

  useEffect(() => {
    if (!initialized && query.query) {
      setSearchValue(query.query as string);
      setSearchStringValue(query.query as string);
    }
  }, [initialized, query.query]);

  useAsync(async () => {
    if (searchString && !initialized) {
      setInitialized(true);
      await executeSearch(searchString);
    }
  }, [initialized, searchString, executeSearch]);

  return (
    <div>
      <StyledShopHeader headerTransparent={false}>
        <TopMenu color="grey" transparent={false} />

        <Grid
          columns={{ lg: '1fr 2fr', md: '1fr' }}
          verticalPadding="unset"
          gap="unset"
          alignItems="inherit"
          alignContent="center"
          height={{ lg: headerHeight, sm: headerHeightSmaller }}
          paddingRight={{ sm: 'unset', md: 'default', lg: 'default' }}>
          <Cell hidden={{ lg: false, md: true }}>
            <Link href="/">
              <FlexContainer alignItems="center" height="100%">
                <Icon
                  name="logo"
                  color="black"
                  width={{ lg: '12rem', sm: '9rem' }}
                  height={{ lg: '3rem', sm: '2rem' }}
                  cursor="pointer"
                />
              </FlexContainer>
            </Link>
          </Cell>
          <Cell>
            <Grid
              columns="2.2rem 1fr 1.8rem"
              padding="unset"
              flow="column"
              alignItems="center"
              alignContent="center"
              paddingRight={{ lg: 'unset', md: 's2', sm: 's1' }}
              gap="1.5rem">
              <Icon name="search" cursor="pointer" top="-3px" />

              <StyledSearchInputContainer>
                <StyledSearchInput
                  ref={inputRef}
                  variant="dark"
                  placeholder="Search"
                  clearable={false}
                  value={searchValue}
                  onTextChange={setSearchStringValue}
                  onFocus={onSearchFocus}
                  onBlur={onSearchInputBlur}
                  onEnter={executeSearch}
                />
              </StyledSearchInputContainer>

              {!!searchValue && (
                <Icon
                  name="close"
                  color="grey"
                  size="1.6rem"
                  cursor="pointer"
                  onClick={onSearchClear}
                />
              )}
            </Grid>
          </Cell>
        </Grid>
      </StyledShopHeader>

      {dropdownVisible && !!suggestedSearches.length && !!quickLinks.length && (
        <StyledSearchDropdown>
          {!!quickLinks.length && (
            <div>
              <Typography variant="h5" tx="search.quickLinks" color="grey" />

              <StackedContainer padding="unset" marginTop="s2">
                {quickLinks.map(item => (
                  <Link
                    key={item.id}
                    href={`/products/${item.id}/${item.url}/${item.sku}`.toLowerCase()}>
                    <a draggable="false">
                      <StackedContainer padding="unset" gap="s2" flow="column">
                        <Thumbnail
                          src={item.thumbnail}
                          size="6rem"
                          border="unset"
                          borderRadius="unset"
                        />
                        <Typography text={item.name} />
                      </StackedContainer>
                    </a>
                  </Link>
                ))}
              </StackedContainer>
            </div>
          )}

          {!!quickLinks.length && !!suggestedSearches.length && (
            <StyledSearchDropdownDivider />
          )}

          {!!suggestedSearches.length && (
            <div>
              <Typography
                variant="h5"
                tx="search.suggestedSearches"
                color="grey"
              />
              <StackedContainer padding="unset" gap="s1" marginTop="s2">
                {suggestedSearches.map(item => (
                  <StyledSearchLink
                    key={item}
                    text={item}
                    textStyle="regular"
                    color="black"
                    onClick={() => onSuggestedSearchSelected(item)}
                  />
                ))}
              </StackedContainer>
            </div>
          )}
        </StyledSearchDropdown>
      )}
    </div>
  );
};

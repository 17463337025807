import React, { memo } from 'react';
import { DropdownItemProps } from 'core/dropdown/dropdown-item.props';
import { StyledDropdownItem } from 'core/dropdown/dropdown.styles';
import Link from 'next/link';

export const DropdownItem = memo((props: DropdownItemProps) => {
  const { to, onClick, children } = props;

  return to ? (
    <Link href={to} passHref={true} prefetch={false}>
      <a>
        <StyledDropdownItem>{children}</StyledDropdownItem>
      </a>
    </Link>
  ) : (
    <StyledDropdownItem onClick={onClick}>{children}</StyledDropdownItem>
  );
});

import { useEffect } from 'react';
import { initializeAnalytics, trackAnalyticsPageEvent } from 'utils/analytics';
import { Router, useRouter } from 'next/router';
import { useNotification } from 'context/notification.context';
import { isBrowser } from '@unly/utils';
import { useViewportMeasures } from 'utils/hooks';

const fileLabel = 'components/Layout';

const eventsMap = [];

export const useLayoutSharedBehavior = () => {
  const { asPath } = useRouter();
  const { showPreloader, hidePreloader } = useNotification();

  useViewportMeasures();

  useEffect(() => {
    initializeAnalytics(asPath);

    Router.events.on('routeChangeStart', showPreloader);
    Router.events.on('routeChangeComplete', url => {
      const lastUrl = eventsMap[eventsMap.length - 1];

      if (url !== lastUrl) {
        trackAnalyticsPageEvent(url);
        eventsMap.push(url);
      }

      hidePreloader();
    });
    Router.events.on('routeChangeError', hidePreloader);
  }, []);
};

import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import { ShopLayoutPresets } from 'layout/layout/shop-layout.presets';
import { useIntersectionObserver } from 'utils/hooks';
import {
  StyledBody,
  StyledContent,
  StyledObservableDiv,
  StyledTopNotificationsContainer,
} from 'layout/layout/shop-layout.styles';
import { ShopFooter } from 'layout/footer';
import { SideMenu } from 'layout/side-menu';
import { useAuthHandler, useFetchCart } from 'src/layout/layout/hooks';
import { useLayout } from 'context/layout.context';
import { useFeedbackModal } from 'modals/feedback';
import { NotificationsManager } from 'layout/notifications-manager';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';
import { useRouter } from 'next/router';
import { Icon } from 'core/icon';
import { StyledFloatingLogo } from 'layout/layout/hooks/common-layout.styles';
import Link from 'next/link';

export const ShopHeaderLessLayout = (props: ShopLayoutProps) => {
  const { children } = Object.assign({}, ShopLayoutPresets, props);
  const { footerVisible } = useLayout();
  const { sideMenuVisible, setHeaderVisibility, hideSideMenu } = useLayout();
  const { asPath } = useRouter();

  useAuthHandler({
    shouldFetchCartInfo: asPath.includes('cart'),
  });

  useFetchCart();
  useFeedbackModal();

  useLayoutSharedBehavior();

  const { observerRef } = useIntersectionObserver({
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      setHeaderVisibility(!isIntersecting);
    },
  });

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledFloatingLogo>
        <Link href="/">
          <a>
            <Icon
              name="logo"
              color={'black'}
              width={{ lg: '12rem', sm: '9rem' }}
              height={{ lg: '3rem', sm: '2rem' }}
              cursor="pointer"
            />
          </a>
        </Link>
      </StyledFloatingLogo>

      <div>
        <StyledObservableDiv ref={observerRef} />

        <StyledContent>{children}</StyledContent>

        {footerVisible && <ShopFooter />}
      </div>
      <SideMenu visible={sideMenuVisible} onMenuClose={hideSideMenu} />
    </StyledBody>
  );
};

import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import { ShopLayoutPresets } from 'layout/layout/shop-layout.presets';
import { useIntersectionObserver } from 'utils/hooks';
import {
  StyledBody,
  StyledContent,
  StyledContentContainer,
  StyledObservableDiv,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/shop-layout.styles';
import { ShopHeader } from 'layout/headers';
import { ShopFooter } from 'layout/footer';
import { SideMenu } from 'layout/side-menu';
import { useRouter } from 'next/router';
import { useAuthHandler, useFetchCart } from 'src/layout/layout/hooks';
import { useLayout } from 'context/layout.context';
import { useFeedbackModal } from 'modals/feedback';
import { NotificationsManager } from 'layout/notifications-manager';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';

export const ShopLayout = (props: ShopLayoutProps) => {
  const { children } = Object.assign({}, ShopLayoutPresets, props);
  const { footerVisible } = useLayout();
  const {
    headerVisibilityLocked,
    sideMenuVisible,
    setHeaderVisibility,
    hideSideMenu,
  } = useLayout();
  const { asPath } = useRouter();

  useAuthHandler({
    shouldFetchCartInfo: asPath.includes('cart'),
  });
  useFetchCart();
  useFeedbackModal();

  useLayoutSharedBehavior();

  const { observerRef } = useIntersectionObserver({
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      setHeaderVisibility(!isIntersecting);
    },
  });

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <StyledTopContainer>
        <ShopHeader />
      </StyledTopContainer>

      <StyledContentContainer padded={headerVisibilityLocked}>
        <StyledObservableDiv ref={observerRef} />

        <StyledContent>{children}</StyledContent>

        {footerVisible && <ShopFooter />}
      </StyledContentContainer>
      <SideMenu visible={sideMenuVisible} onMenuClose={hideSideMenu} />
    </StyledBody>
  );
};

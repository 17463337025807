import React from 'react';
import { MenuItemProps, MenuProps } from './menu.props';
import { MenuPresets } from './menu.presets';
import {
  StyledAccountChevron,
  StyledCartCount,
  StyledCartInfo,
  StyledMenuContainer,
  StyledMenuIconContainer,
  StyledMenuIconLine,
  StyledSearchIcon,
} from './menu.styles';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { FlexContainer } from 'layout/flex-container';
import { MenuItem } from 'layout/menu/menu-item';
import { useTheme } from 'styled-components';
import { theme } from 'styles/theme';
import { BoxContainer } from 'layout/box-container';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuDivider,
} from 'core/dropdown';
import { Typography } from 'core/typography';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Icon } from 'core/icon/icon';
import { environment } from 'config/environment';
import { StackedContainer } from 'layout/stacked-container';

export const Menu = (props: MenuProps) => {
  const {
    authenticated,
    color,
    isDesigner,
    cartItemsCount,
    wishlistCount,
    closeIconActive,
    designerProfileLink,
    onMenuButtonClick,
    onAccountClick,
    onLogout,
  } = Object.assign({}, MenuPresets, props);

  const {
    layout: {
      header: { height: headerHeight, heightSmaller: headerHeightSmaller },
    },
  } = useTheme();

  const router = useRouter();

  const menuItems: MenuItemProps[] = [
    {
      tx: 'shopMenu.shopTable',
      to: '/shop/[category]',
      as: '/shop/table-lights',
    },
    {
      tx: 'shopMenu.shopWall',
      to: '/shop/[category]',
      as: '/shop/wall-lights',
    },
    {
      tx: 'shopMenu.shopFloor',
      to: '/shop/[category]',
      as: '/shop/floor-lights',
    },
    { tx: 'shopMenu.designers', to: '/designers' },
    {
      tx: 'shopMenu.giftCards',
      to: '/gift-cards',
      as: '/gift-cards',
      isNew: true,
    },
  ];

  const isCartRoute = router.pathname.includes('/cart');

  const textColor = isCartRoute ? 'white' : color;
  const isCartIconEmptyPrimary =
    cartItemsCount > 0 && color === 'grey' && !isCartRoute;

  const cartIconColor = isCartRoute ? theme.colors.white : color;

  const menu = (
    <DropdownMenu width="23.8rem">
      {isDesigner && (
        <>
          <DropdownItem to={`${environment.CREATE_HUB_URL}/dashboard`}>
            <Typography tx="shopMenu.createHub" color="primary" />
          </DropdownItem>

          <DropdownItem to={`/${designerProfileLink}`}>
            <Typography tx="shopMenu.storefront" color="grey" />
          </DropdownItem>

          <DropdownMenuDivider />
        </>
      )}
      <DropdownItem to="/account/settings">
        <Typography tx="shopMenu.settings" color="grey" />
      </DropdownItem>

      <DropdownItem to="/account/addresses">
        <Typography tx="shopMenu.addresses" color="grey" />
      </DropdownItem>

      <DropdownItem to="/account/payments">
        <Typography tx="shopMenu.payments" color="grey" />
      </DropdownItem>

      <DropdownItem to="/account/wishlist">
        <StackedContainer
          columns="repeat(3, max-content)"
          gap="6px"
          padding={'unset'}
          alignItems={'center'}>
          <Typography tx="shopMenu.wishlist" color="grey" />
          {!!wishlistCount && (
            <>
              <Icon name="star" size="1.8rem" color="gold" />
              <Typography variant="p3" text={wishlistCount} color="gold" />
            </>
          )}
        </StackedContainer>
      </DropdownItem>

      <DropdownItem to="/account/orders">
        <Typography tx="shopMenu.orders" color="grey" />
      </DropdownItem>

      <DropdownMenuDivider />

      <DropdownItem onClick={onLogout}>
        <Typography tx="shopMenu.logout" color="grey" />
      </DropdownItem>
    </DropdownMenu>
  );

  return (
    <Grid
      columns={{ lg: 3, md: 2 }}
      verticalPadding="unset"
      gap="unset"
      alignItems="inherit"
      alignContent="center"
      height={{ lg: headerHeight, sm: headerHeightSmaller }}
      paddingRight={{ sm: 'unset', md: 'default', lg: 'default' }}>
      <Cell>
        <Link href="/">
          <FlexContainer alignItems="center" height="100%">
            <Icon
              name="logo"
              color={color === 'grey' ? 'black' : 'white'}
              width={{ lg: '12rem', sm: '9rem' }}
              height={{ lg: '3rem', sm: '2rem' }}
              cursor="pointer"
            />
          </FlexContainer>
        </Link>
      </Cell>
      <Cell hidden={{ lg: false, md: true }}>
        <StyledMenuContainer>
          {menuItems.map(item => (
            <MenuItem item={item} color={color} key={item.tx ?? item.text} />
          ))}
        </StyledMenuContainer>
      </Cell>
      <Cell>
        <Grid
          columns={{ lg: 'repeat(3, 7rem)', md: 'repeat(2, max-content)' }}
          gap="unset"
          justifyItems="flex-end"
          justifyContent="flex-end"
          alignItems="center"
          padding="unset">
          <BoxContainer hidden={{ lg: false, md: true }}>
            <Link href="/search">
              <StyledSearchIcon name="search" color={color} size="2rem" />
            </Link>
          </BoxContainer>

          <BoxContainer hidden={{ lg: false, md: true }}>
            <Dropdown canShowDropdown={authenticated} menu={menu}>
              <FlexContainer alignItems="center">
                <Icon
                  name="user"
                  color={color}
                  size="2.4rem"
                  onClick={onAccountClick}
                />
                {authenticated && (
                  <StyledAccountChevron
                    name="chevron-down"
                    color={color}
                    size="1rem"
                  />
                )}
              </FlexContainer>
            </Dropdown>
          </BoxContainer>

          <Link href="/cart">
            <StyledCartInfo active={isCartRoute}>
              <Icon
                name="cart"
                height="2rem"
                color={isCartIconEmptyPrimary ? 'primary' : cartIconColor}
                cursor="pointer"
              />
              {!!cartItemsCount && (
                <StyledCartCount
                  variant="p3"
                  text={cartItemsCount}
                  color={textColor}
                />
              )}
            </StyledCartInfo>
          </Link>

          <StyledMenuIconContainer onClick={onMenuButtonClick}>
            <StyledMenuIconLine color={color} active={closeIconActive} />
            <StyledMenuIconLine color={color} active={closeIconActive} />
          </StyledMenuIconContainer>
        </Grid>
      </Cell>
    </Grid>
  );
};

import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledContentContainer = styled.div<{
  padded?: boolean;
  editBarVisible?: boolean;
}>`
  ${({ padded, editBarVisible, theme }) => {
    const extraPadding = editBarVisible ? '8rem' : '3.2rem';
    const extraPaddingSmall = editBarVisible ? '4.8rem' : '0rem';

    return css`
      position: relative;
      overflow-x: hidden;
      z-index: 0;
      padding-top: ${padded
        ? `calc(${theme.layout.header.height} + ${extraPadding})`
        : 'unset'};

      ${media.lessThan('md')`
      padding-top: ${
        padded
          ? `calc(${theme.layout.header.height} + ${extraPadding})`
          : 'unset'
      };
    `}

      ${media.lessThan('sm')`
      padding-top: ${
        padded
          ? `calc(${theme.layout.header.heightSmaller} + ${extraPaddingSmall})`
          : 'unset'
      };
    `}
    `;
  }}
`;

import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { getThemeColor } from 'styles/theme';

export const StyledSlideToggle = styled(animated.div)`
  width: 3.6rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  position: relative;
  padding: 0.2rem;
  cursor: pointer;
  user-select: none;
`;

export const StyledToggleBall = styled(animated.div)<{ color: string }>`
  ${({ color }) => css`
    width: 1.8rem;
    height: 1.8rem;
    background-color: ${getThemeColor(color)};
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  `}
`;

import { Typography } from 'core/typography';
import React, { ReactNode, useState } from 'react';
import {
  StyleBackArrowIconContainer,
  StyledSideMenu,
  StyledSideMenuContainer,
  StyledSideMenuLink,
  StyledSubmenuTitle,
} from 'layout/side-menu/side-menu.styles';
import { useSpring } from 'react-spring';
import { FlexContainer } from 'layout/flex-container';
import { MenuItemProps } from 'layout/menu/menu.props';
import Link from 'next/link';
import { Icon } from 'core/icon/icon';

export const SideMenuItem = props => {
  const { item, lineHeight } = props;

  const [opened, setOpened] = useState(false);

  const toggleOpened = (): void => {
    setOpened(!opened);
  };

  const spring = useSpring({
    width: opened ? 300 : 0,
    config: { duration: 100 },
  });

  const renderMenuItem = (subItem: MenuItemProps): ReactNode => (
    <Link
      href={subItem.to ?? '/'}
      passHref={true}
      prefetch={false}
      as={subItem.as}
      key={subItem.tx ?? subItem.text}>
      <a>
        <StyledSideMenuLink>
          <Typography
            tx={subItem.tx}
            text={subItem.text}
            fontSize="1.4rem"
            lineHeight="4rem"
            color={subItem.color ?? 'grey'}
          />
        </StyledSideMenuLink>
      </a>
    </Link>
  );

  return (
    <div>
      <StyledSubmenuTitle onClick={toggleOpened}>
        <Typography
          fontSize="1.4rem"
          tx={item.tx}
          text={item.text}
          color={item.color ?? 'grey'}
          lineHeight="4rem"
        />
      </StyledSubmenuTitle>

      <StyledSideMenuContainer style={spring}>
        <StyledSideMenu>
          <FlexContainer>
            <StyledSubmenuTitle onClick={toggleOpened}>
              <StyleBackArrowIconContainer>
                <Icon name="arrow-left" color="grey" width="2rem" />
              </StyleBackArrowIconContainer>

              <Typography
                variant="p1"
                tx={item.tx}
                text={item.text}
                color={item.color ?? 'grey'}
                lineHeight={lineHeight ?? '4rem'}
              />
            </StyledSubmenuTitle>
          </FlexContainer>
        </StyledSideMenu>

        <StyledSideMenu>
          {item.submenu.map((subItem: MenuItemProps) =>
            renderMenuItem(subItem),
          )}
        </StyledSideMenu>
      </StyledSideMenuContainer>
    </div>
  );
};

import {
  StyledMenuActiveIndicator,
  StyledMenuItem,
  StyledMenuItemContainer,
  StyledMenuItemText,
  StyledNewTag,
} from 'layout/menu/menu.styles';
import React from 'react';
import { useSpring } from 'react-spring';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Icon } from 'core/icon/icon';
import { useTheme } from 'styled-components';

export const MenuItem = props => {
  const { item, color, onClick } = props;
  const router = useRouter();
  const theme = useTheme();

  const animatedProps = useSpring({
    backgroundColor: color === 'grey' ? theme.colors.black : theme.colors.white,
  });

  const onMenuItemClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const active = router.asPath.includes(item.as ?? item.to) && !item.submenu;

  const menuItem = (
    <StyledMenuItemContainer onClick={onMenuItemClick}>
      <StyledMenuItemText
        tx={item.tx}
        text={item.text}
        color={active && color === 'grey' ? 'black' : item.color ?? color}
        variant={item.textVariant ?? 'p2'}
      />

      {item.submenu && (
        <Icon name="chevron-down" height="1rem" width="1rem" color={color} />
      )}

      {item.isNew && (
        <StyledNewTag
          tx="shopMenu.new"
          color={color === 'white' ? 'white' : 'primary'}
          variant="p3"
          textStyle="bold"
        />
      )}

      {active && <StyledMenuActiveIndicator style={animatedProps} />}
    </StyledMenuItemContainer>
  );

  return (
    <StyledMenuItem>
      {item.submenu ? (
        menuItem
      ) : (
        <Link href={item.to ?? '/'} as={item.as}>
          <a>{menuItem}</a>
        </Link>
      )}
    </StyledMenuItem>
  );
};

import {
  ANALYTICS_IDENTIFY_REGISTERED,
  AUTH_TOKEN,
  CART_ITEMS,
  CART_OUTDATED,
  FIREBASE_TOKEN,
} from 'constants/common';
import { useCookies } from 'react-cookie';
import { useAuth } from 'context/auth.context';
import { useRouter } from 'next/router';
import { api } from 'services/api';
import { useAsync } from 'react-use';
import { useCart } from 'context/cart.context';
import { User } from 'types/user.type';
import { identifyLogRocket } from 'utils/log-rocket';
import { useEffect } from 'react';
import { getAnalyticsUserId, identifyAnalyticsUser } from 'utils/analytics';
import { useMounted } from 'utils/hooks';
import { useNotification } from 'context/notification.context';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
  saveToSessionStorage,
} from 'utils/storage';
import { environment } from 'config/environment';
import { v4 as uuidv4 } from 'uuid';

export interface UseAuthHandlerProps {
  requiresAuthorization?: boolean;
  shouldFetchCartInfo?: boolean;
}

export const useAuthHandler = (props: UseAuthHandlerProps = {}) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    AUTH_TOKEN,
    FIREBASE_TOKEN,
    CART_OUTDATED,
  ]);

  const [, authDispatch] = useAuth();
  const [{ initialized: cartInitialized }, cartDispatch] = useCart();
  const { asPath, push: pushRoute } = useRouter();
  const { showPreloader, hidePreloader } = useNotification();

  const mounted = useMounted();

  useEffect(() => {
    setTimeout(() => {
      authDispatch({
        type: 'UPDATE_BROWSER_ID',
        id: getAnalyticsUserId(),
      });
    }, 3000);
  }, []);

  useEffect(() => {
    if (!!cookies[CART_OUTDATED]) {
      removeCookie(CART_OUTDATED, { domain: environment.COOKIES_DOMAIN });
      removeFromLocalStorage(CART_ITEMS);
    }
  }, []);

  useAsync(async () => {
    if (mounted) {
      const searchParams = new URLSearchParams(asPath.split('?')[1]);

      const utm_campaign = searchParams.get('utm_campaign');
      const utm_content = searchParams.get('utm_content');
      const utm_medium = searchParams.get('utm_medium');
      const utm_source = searchParams.get('utm_source');

      authDispatch({
        type: 'SET_PROPERTIES',
        data: {
          status: 'FETCHING',
        },
      });

      if (utm_campaign) {
        setCookie('utm_campaign', utm_campaign, { path: '/' });
      }

      if (utm_content) {
        setCookie('utm_content', utm_content, { path: '/' });
      }

      if (utm_medium) {
        setCookie('utm_medium', utm_medium, { path: '/' });
      }

      if (utm_source) {
        setCookie('utm_source', utm_source, { path: '/' });
      }

      const authToken = cookies[AUTH_TOKEN];
      let authenticated = !!authToken;
      let firebaseToken = cookies[FIREBASE_TOKEN];

      const { data: timeData } = await api.product.fetchLeadTime();

      if (!!timeData?.productLeadTime) {
        cartDispatch({
          type: 'SET_PROPERTIES',
          data: {
            leadTime: timeData?.productLeadTime,
          },
        });
      }

      let loggedUser: User;
      if (authenticated) {
        api.registerAuthHeader(authToken);

        const { data } = await api.auth.getLogged();

        if (data.success) {
          if (!cartInitialized) {
            const { data: cartData } = await api.user.fetchCart();

            const records = (cartData?.cart || []).map(
              record =>
                Object.assign({}, record, {
                  cartId: record.cartId || uuidv4(),
                }) as any,
            );

            cartDispatch({
              type: 'SET_PROPERTIES',
              data: {
                records,
                wishlist: data.data.wishlist,
                count: data.data?.cart.length ?? 0,
                credit: data.data.credit || 0,
              },
            });

            saveToLocalStorage(CART_ITEMS, records);
          }

          delete data.data.cart;
          delete data.data.wishlist;

          loggedUser = data.data;
          firebaseToken = data.firebaseToken;

          identifyLogRocket(
            `${data.data.firstName} ${data.data.lastName}`,
            data.data.email,
          );

          const analyticsIdentify = getFromLocalStorage(
            ANALYTICS_IDENTIFY_REGISTERED,
          );

          if (!analyticsIdentify) {
            identifyAnalyticsUser(data.data);

            saveToSessionStorage(ANALYTICS_IDENTIFY_REGISTERED, '1');
          }
        } else {
          authenticated = false;
          api.clearAuthHeader();
        }
      }

      if (!authenticated) {
        const cartItems = getFromLocalStorage(CART_ITEMS) || [];

        cartDispatch({
          type: 'SET_CART_RECORDS',
          records: cartItems,
        });

        if (props?.requiresAuthorization) {
          await pushRoute(`/sign-up?redirectUrl=${asPath}`);
        }
      }

      authDispatch({
        type: 'SET_PROPERTIES',
        data: {
          authenticated,
          token: authenticated && cookies[AUTH_TOKEN],
          firebaseToken: authenticated && firebaseToken,
          user: loggedUser,
          status: authenticated ? 'AUTHENTICATED' : 'NOT AUTHENTICATED',
          campaignInfo: {
            utm_campaign:
              searchParams.get('utm_campaign') ?? cookies.utm_campaign,
            utm_content: searchParams.get('utm_content') ?? cookies.utm_content,
            utm_medium: searchParams.get('utm_medium') ?? cookies.utm_medium,
            utm_source: searchParams.get('utm_source') ?? cookies.utm_source,
            source: process.browser ? window.location.href : '',
            browserUserId: getAnalyticsUserId(),
          },
        },
      });
    }
  }, [
    cartInitialized,
    mounted,
    cookies[AUTH_TOKEN],
    props.shouldFetchCartInfo,
  ]);
};

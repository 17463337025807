import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import { ShopLayoutPresets } from 'layout/layout/shop-layout.presets';
import {
  StyledBody,
  StyledContent,
  StyledTopNotificationsContainer,
} from 'layout/layout/shop-layout.styles';
import { useAuthHandler, useFetchCart } from 'src/layout/layout/hooks';
import { NotificationsManager } from 'layout/notifications-manager';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';
import { useRouter } from 'next/router';

export const ShopBaseLayout = (props: ShopLayoutProps) => {
  const { children } = Object.assign({}, ShopLayoutPresets, props);
  const { asPath } = useRouter();

  useAuthHandler({
    shouldFetchCartInfo: asPath.includes('cart'),
  });

  useFetchCart();
  useLayoutSharedBehavior();

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      <div>
        <StyledContent>{children}</StyledContent>
      </div>
    </StyledBody>
  );
};

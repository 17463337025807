import { TypographyWithIcon } from 'core/typography-with-icon';
import { SlideToggle } from 'core/slide-toggle';
import {
  StyledColorInputContainer,
  StyledColorPopup,
  StyledColorPreview,
  StyledColorSelector,
  StyledColorSelectorsContainer,
  StyledEditHeader,
} from 'layout/headers/designer-header/designer-header.styles';
import { TextInput } from 'core/text-input';
import React, { useCallback, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { useMediaLarge, useMounted } from 'utils/hooks';
import { useLayout } from 'context/layout.context';
import { ChromePicker } from 'react-color';
import { BoxContainer } from 'layout/box-container';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { trackAnalyticsEvent } from 'utils/analytics';

export const DesignerEditHeader = () => {
  const {
    editingDesigner,
    designerHighlightColor,
    designerBackgroundColor,
    setDesignerHighlightColor,
    setDesignerBackgroundColor,
    setEditingDesigner,
  } = useLayout();

  const [colorSelectorExpanded, toggleColorSelectorExpanded] = useToggle(false);

  const { height } = useSpring({
    height: colorSelectorExpanded ? 115 : 0,
  });

  const [backgroundPickerVisible, toggleBackgroundPickerVisible] = useToggle(
    false,
  );
  const [highlightPickerVisible, toggleHighlightPickerVisible] = useToggle(
    false,
  );
  const mounted = useMounted();
  const large = useMediaLarge();

  const backgroundRef = useRef();
  useClickAway(backgroundRef, () => {
    toggleBackgroundPickerVisible();
  });

  const highlightRef = useRef();
  useClickAway(highlightRef, () => {
    toggleHighlightPickerVisible();
  });

  const onBackgroundChange = useCallback(({ hex }) => {
    setDesignerBackgroundColor(hex);
  }, []);

  const onHighlightChange = useCallback(({ hex }) => {
    setDesignerHighlightColor(hex);
  }, []);

  const onEditChange = useCallback(async (value: boolean) => {
    setEditingDesigner(value);

    trackAnalyticsEvent('Designer Toggle Edit Mode', { value });
  }, []);

  const colorsSelector = (
    <StyledColorSelectorsContainer>
      <StyledColorSelector>
        <TypographyWithIcon
          text="Background #"
          variant="p3"
          textStyle="bold"
          icon={
            <StyledColorPreview color={designerBackgroundColor || '#f4f4f4'} />
          }
        />

        <StyledColorInputContainer>
          <TextInput
            onClick={toggleBackgroundPickerVisible}
            onTextChange={value => onBackgroundChange({ hex: `#${value}` })}
            value={designerBackgroundColor?.substr(1) || 'f4f4f4'}
            clearable={false}
          />

          {mounted && backgroundPickerVisible && (
            <StyledColorPopup ref={backgroundRef}>
              <ChromePicker
                color={designerBackgroundColor}
                disableAlpha={true}
                onChangeComplete={onBackgroundChange}
              />
            </StyledColorPopup>
          )}
        </StyledColorInputContainer>
      </StyledColorSelector>

      <StyledColorSelector>
        <TypographyWithIcon
          text="Highlight #"
          variant="p3"
          textStyle="bold"
          icon={
            <StyledColorPreview color={designerHighlightColor || '#1b7b76'} />
          }
        />

        <StyledColorInputContainer>
          <TextInput
            onClick={toggleHighlightPickerVisible}
            value={designerHighlightColor?.substr(1) || '1b7b76'}
            onTextChange={value => onHighlightChange({ hex: `#${value}` })}
            clearable={false}
          />

          {mounted && highlightPickerVisible && (
            <StyledColorPopup ref={highlightRef}>
              <ChromePicker
                color={designerHighlightColor}
                disableAlpha={true}
                onChangeComplete={onHighlightChange}
              />
            </StyledColorPopup>
          )}
        </StyledColorInputContainer>
      </StyledColorSelector>
    </StyledColorSelectorsContainer>
  );

  return (
    <div>
      <StyledEditHeader>
        <TypographyWithIcon
          text="Editing mode"
          variant="p3"
          icon={
            <SlideToggle active={editingDesigner} onChange={onEditChange} />
          }
          iconPosition="right"
        />

        {mounted && large && editingDesigner && colorsSelector}

        <BoxContainer hidden={{ lg: true, md: !editingDesigner }}>
          <Button
            text="Colors"
            variant="action"
            textVariant="p3"
            textStyle="regular"
            textColor="black"
            icon={
              <Icon name="menu-down" color="black" size="1.2rem" top="2px" />
            }
            iconLocation="right"
            onClick={toggleColorSelectorExpanded}
          />
        </BoxContainer>
      </StyledEditHeader>

      {mounted && !large && editingDesigner && (
        <animated.div
          style={{
            height,
            overflow:
              backgroundPickerVisible || highlightPickerVisible
                ? 'visible'
                : 'hidden',
          }}>
          {colorsSelector}
        </animated.div>
      )}
    </div>
  );
};

import React, { useEffect, useRef } from 'react';
import { StyledNotificationsManager } from './notifications-manager.styles';
import { LoadingOverlay } from 'layout/loading-overlay';
import { animated, useSpring } from 'react-spring';
import { useNotification } from 'context/notification.context';
import { useTheme } from 'styled-components';
import { Typography } from 'core/typography';
import useResizeObserver from 'use-resize-observer/polyfilled';

export const NotificationsManager = () => {
  const {
    visible,
    hideNotification,
    isError,
    message,
    messageTx,
    timeout,
    preloaderVisible,
    preloaderBackgroundColor,
  } = useNotification();

  const theme = useTheme();

  const textContainerRef = useRef();
  const { height } = useResizeObserver({ ref: textContainerRef });

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        hideNotification();
      }, timeout ?? 3000);
    }
  });

  const spring = useSpring({
    from: { height: 0 },
    to: {
      height: visible ? Math.max((height || 0) + 12, 40) : 0,
    },
    config: {
      tension: 500,
      duration: 300,
      friction: 500,
    },
  });

  return (
    <animated.div style={spring}>
      <StyledNotificationsManager
        style={{
          ...spring,
          backgroundColor: isError ? theme.colors.red : theme.colors.blue,
        }}>
        <div ref={textContainerRef}>
          {(messageTx || message) && (
            <Typography tx={messageTx} text={message} color="white" />
          )}
        </div>
      </StyledNotificationsManager>

      {preloaderVisible && (
        <LoadingOverlay background={preloaderBackgroundColor} />
      )}
    </animated.div>
  );
};

import React, { useCallback } from 'react';
import { StyledShopHeader } from './shop-header.styles';
import { Menu } from 'layout/menu';
import { useSignInModal } from 'modals/sign-in';
import { TopMenu } from 'layout/top-menu';
import { useAuth } from 'context/auth.context';
import { useCart } from 'context/cart.context';
import { useLayout } from 'context/layout.context';
import { useSignOut } from 'modals/sign-in/sign-in.hooks';

export const ShopHeader = () => {
  const {
    headerVisibilityLocked,
    headerVisible,
    sideMenuVisible,
    toggleSideMenuVisibility,
  } = useLayout();

  const [{ authenticated, user }] = useAuth();
  const [{ records, wishlist }] = useCart();
  const { signOut } = useSignOut();

  const { showModal } = useSignInModal({ signUpEventName: 'Sign Up - Header' });

  const isHeaderVisible =
    headerVisible || sideMenuVisible || headerVisibilityLocked;

  const onSignUpModalShow = useCallback((): void => {
    if (!authenticated) {
      showModal();
    }
  }, [authenticated]);

  return (
    <StyledShopHeader headerTransparent={!isHeaderVisible}>
      <TopMenu
        color={isHeaderVisible ? 'grey' : 'white'}
        transparent={!isHeaderVisible}
      />

      <Menu
        isDesigner={!!user?.designerInfo}
        color={isHeaderVisible ? 'grey' : 'white'}
        cartItemsCount={records.length}
        closeIconActive={sideMenuVisible}
        wishlistCount={wishlist.length ?? 0}
        authenticated={authenticated}
        designerProfileLink={user?.designerInfo?.profileLink}
        onMenuButtonClick={toggleSideMenuVisibility}
        onAccountClick={onSignUpModalShow}
        onLogout={signOut}
      />
    </StyledShopHeader>
  );
};

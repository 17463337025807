import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { AccountMenuItem } from 'layout/account-menu/sections';

export const AccountMenu = () => {
  return (
    <StackedContainer padding={'unset'} gap="s2">
      <AccountMenuItem
        text="Settings"
        linkHref="/account/settings"
        iconName="settings"
      />

      <AccountMenuItem
        text="Addresses"
        linkHref="/account/addresses"
        iconName="address"
      />

      <AccountMenuItem
        text="Payments"
        linkHref="/account/payments"
        iconName="blank-card"
      />

      <AccountMenuItem
        text="Wishlist"
        linkHref="/account/wishlist"
        iconName="wishlist"
      />

      <AccountMenuItem
        text="Orders"
        linkHref="/account/orders"
        iconName="box"
      />
    </StackedContainer>
  );
};

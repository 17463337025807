import React from 'react';
import { Grid } from 'layout/grid';
import { Icon } from 'core/icon/icon';

export const ShippingBannerPrimary = () => (
  <Grid
    columns="repeat(3, max-content)"
    backgroundColor="primary"
    verticalPadding="s5"
    columnGap={{ lg: '14%', sm: 'unset' }}
    justifyContent={{ lg: 'center', sm: 'space-between' }}
    horizontalPadding="s2">
    <Icon
      name="free-shipping"
      color="white"
      size={{ lg: '12rem', md: '9rem' }}
    />

    <Icon
      name="30-days-return"
      color="white"
      size={{ lg: '12rem', md: '9rem' }}
    />

    <Icon
      name="1-year-guarantee"
      color="white"
      size={{ lg: '12rem', md: '9rem' }}
    />
  </Grid>
);

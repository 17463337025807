import {
  StyledFooter,
  StyledFooterSectionLeft,
  StyledFooterSectionRight,
  StyledPolicyLinks,
  StyledSocialButtonsContainer,
} from './footer.styles';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Spacer } from 'layout/spacer';
import { TextInput } from 'core/text-input';
import { RoundButton } from 'core/round-button';
import React, { memo, useState } from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { BoxContainer } from 'layout/box-container';
import { Icon } from 'core/icon/icon';
import Link from 'next/link';
import { useAuth } from 'context/auth.context';
import { useNotification } from 'context/notification.context';
import { useSubscribe } from 'src/common/hooks';
import { useCopyToClipboard } from 'react-use';
import { environment } from 'config/environment';
import { Button } from 'core/button';

const shopLinks = [
  { text: 'footer.links.tableLights', path: '/shop/table-lights' },
  { text: 'footer.links.wallLights', path: '/shop/wall-lights' },
  { text: 'footer.links.floorLights', path: '/shop/floor-lights' },
  { text: 'footer.links.designers', path: '/designers' },
  { text: 'footer.links.refer', path: '/refer' },
];

const aboutLinks = [
  { text: 'footer.links.ourStory', path: '/about' },
  { text: 'footer.links.materials', path: '/materials' },
  { text: 'footer.links.factory', path: '/factory' },
  {
    text: 'footer.links.create',
    path: environment.CREATE_HUB_URL,
  },
  { text: 'footer.links.blog', path: '/blog' },
];

const companyLinks = [
  { text: 'footer.links.press', path: '/press' },
  { text: 'footer.links.affiliates', path: '/affiliates' },
];

const helpLinks = [
  {
    text: 'footer.links.faqs',
    path: 'https://support.gantri.com/',
    external: true,
  },
  {
    text: 'footer.links.contact',
    path: 'mailto:support@gantri.com',
    external: true,
  },
];

const year = new Date().getFullYear();

export const ShopFooter = memo(() => {
  const [email, setEmail] = useState('');
  const [{ authenticated, user }] = useAuth();
  const { notify } = useNotification();
  const subscribe = useSubscribe({ analyticsEvent: 'Sign Up - Footer' });
  const [, copyToClipboard] = useCopyToClipboard();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const copyTextToClipboard = (): void => {
    copyToClipboard(user?.referralLink);

    notify({
      messageTx: 'common.linkCopied',
    });
  };

  const onIconClick = async () => {
    if (authenticated) {
      inputRef.current.select();
      copyTextToClipboard();
    } else {
      await subscribe(email);
    }
  };

  return (
    <StyledFooter>
      <StyledFooterSectionLeft>
        <StackedContainer
          gap="s4"
          padding="unset"
          justifyContent="unset"
          columns="unset"
          maxWidth="38rem"
          autoRows="max-content">
          <div>
            <Typography
              type="label"
              tx="footer.inspired"
              variant="h3"
              color="white"
              htmlFor="subscribeEmail"
            />

            <StyledSocialButtonsContainer>
              <Button
                text="Instagram"
                color="white"
                textColor="black"
                linkHref="http://www.instagram.com/gantri"
                linkTarget="_blank"
                linkPrefetch={false}
                icon={<Icon name="instagram-colored" size="2.4rem" />}
              />

              <Button
                text="Pinterest"
                color="white"
                textColor="black"
                linkHref="https://www.pinterest.com/gantridesigns"
                linkTarget="_blank"
                linkPrefetch={false}
                icon={<Icon name="pinterest-colored" size="2.4rem" />}
              />
            </StyledSocialButtonsContainer>

            <Spacer height="s1" />

            <TextInput
              id="subscribeEmail"
              ref={inputRef}
              variant="dark"
              labelTx={
                authenticated
                  ? 'footer.inputLabelAuthenticated'
                  : 'footer.inputLabel'
              }
              labelColor="white"
              placeholderTx={authenticated ? '' : 'footer.email'}
              value={authenticated ? user?.referralLink : ''}
              onTextChange={value => setEmail(String(value))}
              icon={
                authenticated ? null : (
                  <RoundButton
                    icon={<Icon name="right-arrow" color="white" />}
                    color="primary"
                  />
                )
              }
              actionTx={authenticated ? 'footer.copy' : null}
              actionColor="white"
              readonly={authenticated}
              selectOnFocus={true}
              onIconClick={onIconClick}
              onActionClick={onIconClick}
            />
          </div>

          <BoxContainer hidden={{ lg: false, md: true }}>
            <Typography
              tx="footer.rights"
              txValues={{ year }}
              color="grey"
              variant="p3"
            />

            <StyledPolicyLinks>
              <Typography
                tx="footer.links.privacy"
                color="grey"
                variant="link"
                fontSize="1.2rem"
                textStyle="regular"
                href="/legal/privacy"
              />
              <Typography
                tx="footer.links.terms"
                color="grey"
                variant="link"
                fontSize="1.2rem"
                textStyle="regular"
                href="/legal/terms"
              />
            </StyledPolicyLinks>
          </BoxContainer>
        </StackedContainer>
      </StyledFooterSectionLeft>

      <StyledFooterSectionRight>
        <Grid
          columns={{ lg: 'repeat(4, max-content)', md: '2' }}
          padding="unset"
          gap={{ lg: '8.33%', md: 's3', sm: 's2' }}
          paddingLeft={{ lg: 's3', md: 'unset' }}
          justifyContent={{ lg: 'space-between', md: 'flex-start' }}
          maxWidth={{ lg: '50vw', md: 'unset' }}>
          <Cell>
            <div>
              <Typography tx="footer.links.shop" variant="h5" color="white" />
              <Spacer height="10px" />
              <StackedContainer padding="unset" gap="7px" height="unset">
                {shopLinks.map(link => (
                  <Link href={link.path} prefetch={false} key={link.text}>
                    <a>
                      <Typography tx={link.text} color="translucentWhite" />
                    </a>
                  </Link>
                ))}
              </StackedContainer>
            </div>
          </Cell>
          <Cell>
            <div>
              <Typography tx="footer.links.about" variant="h5" color="white" />
              <Spacer height="10px" />
              <StackedContainer padding="unset" gap="7px" height="unset">
                {aboutLinks.map(link => (
                  <Link href={link.path} prefetch={false} key={link.text}>
                    <a>
                      <Typography tx={link.text} color="translucentWhite" />
                    </a>
                  </Link>
                ))}
              </StackedContainer>
            </div>
          </Cell>
          <Cell>
            <div>
              <Typography
                tx="footer.links.company"
                variant="h5"
                color="white"
              />
              <Spacer height="10px" />
              <StackedContainer padding="unset" gap="7px" height="unset">
                {companyLinks.map(link => (
                  <Link href={link.path} prefetch={false} key={link.text}>
                    <a>
                      <Typography tx={link.text} color="translucentWhite" />
                    </a>
                  </Link>
                ))}
              </StackedContainer>
            </div>
          </Cell>
          <Cell>
            <div>
              <Typography
                tx="footer.links.help"
                variant="h5"
                color="white"
                transform="uppercase"
              />
              <Spacer height="10px" />
              <StackedContainer padding="unset" gap="7px" height="unset">
                {helpLinks.map(link => (
                  <Link
                    href={link.path}
                    passHref={link.external}
                    prefetch={false}
                    key={link.text}>
                    <a target={link.external ? '_blank' : '_self'}>
                      <Typography tx={link.text} color="translucentWhite" />
                    </a>
                  </Link>
                ))}
              </StackedContainer>
            </div>
          </Cell>
        </Grid>
        <BoxContainer hidden={{ lg: true, md: false }} marginTop="s3">
          <Typography
            tx="footer.rights"
            txValues={{ year }}
            color="grey"
            variant="p3"
          />
          <StyledPolicyLinks>
            <Typography
              tx="footer.links.privacy"
              color="grey"
              variant="link"
              fontSize="1.2rem"
              textStyle="regular"
              href="/legal/privacy"
            />
            <Typography
              tx="footer.links.terms"
              color="grey"
              variant="link"
              fontSize="1.2rem"
              textStyle="regular"
              href="/legal/terms"
            />
          </StyledPolicyLinks>
        </BoxContainer>
      </StyledFooterSectionRight>
    </StyledFooter>
  );
});

import React from 'react';
import { ShopLayoutProps } from 'layout/layout/shop-layout.props';
import {
  StyledCheckoutBody,
  StyledContent,
  StyledContentContainer,
  StyledTopContainer,
} from 'layout/layout/shop-layout.styles';
import { CheckoutHeader } from 'layout/headers';
import { useAuthHandler } from 'src/layout/layout/hooks';
import { useLayoutSharedBehavior } from 'layout/layout/hooks/layout.hooks';

export const RedeemLayout = (props: ShopLayoutProps) => {
  useLayoutSharedBehavior();

  useAuthHandler({
    shouldFetchCartInfo: false,
  });

  return (
    <StyledCheckoutBody>
      <StyledTopContainer>
        <CheckoutHeader />
      </StyledTopContainer>

      <StyledContentContainer padded={true} topMenuHeight="0rem">
        <StyledContent>{props.children}</StyledContent>
      </StyledContentContainer>
    </StyledCheckoutBody>
  );
};

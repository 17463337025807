import styled, { css } from 'styled-components/macro';
import { media } from 'styles/media';

export const StyledBody = styled.div`
  display: grid;
`;

export const StyledTopNotificationsContainer = styled.div`
  position: relative;
  z-index: 1000011;
`;

export const StyledTopContainer = styled.div`
  position: relative;
  z-index: 10;
`;

export const StyledContentContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 0;
    padding-top: calc(
      ${theme.layout.header.height} + ${theme.layout.topMenuHeight}
    );

    ${media.lessThan('sm')`
      padding-top: calc(${theme.layout.header.heightSmaller} + ${theme.layout.topMenuHeightSmaller});
    `}
  `}
`;

export const StyledContent = styled.div`
  display: inherit;
  position: relative;
`;

export const StyledObservableDiv = styled.div`
  width: 100%;
  height: 3px;
  position: absolute;
  top: 1px;
  z-index: 100000;
`;

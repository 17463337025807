import React, { useEffect, useState } from 'react';
import { SlideToggleProps } from 'core/slide-toggle/slide-toggle.props';
import { SlideTogglePresets } from 'core/slide-toggle/slide-toggle.presets';
import {
  StyledSlideToggle,
  StyledToggleBall,
} from 'core/slide-toggle/slide-toggle.styles';
import { useSpring } from 'react-spring';
import * as easings from 'd3-ease';
import { getThemeColor } from 'styles/theme';

export const SlideToggle = (props: SlideToggleProps) => {
  const {
    active,
    activeColor,
    inactiveColor,
    ballColor,
    onChange,
  } = Object.assign({}, SlideTogglePresets, props);

  const [isActive, setIsActive] = useState(active);
  const spring = useSpring({
    right: isActive ? '0.3rem' : '1.5rem',
    backgroundColor: getThemeColor(isActive ? activeColor : inactiveColor),
    config: {
      duration: 200,
      easing: easings.easeCubicOut,
    },
  });

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const toggleState = () => {
    const newValue = !isActive;

    setIsActive(newValue);

    if (onChange) {
      setTimeout(() => {
        onChange(newValue);
      }, 200);
    }
  };

  return (
    <StyledSlideToggle
      onClick={toggleState}
      style={{ backgroundColor: spring.backgroundColor }}>
      <StyledToggleBall color={ballColor} style={{ right: spring.right }} />
    </StyledSlideToggle>
  );
};

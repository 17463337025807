import React from 'react';
import { TopMenuProps } from 'layout/top-menu/top-menu.props';
import {
  StyledAccountChevron,
  StyledTopMenu,
} from 'layout/top-menu/top-menu.styles';
import { TopMenuPresets } from 'layout/top-menu/top-menu.presets';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Dropdown, DropdownItem, DropdownMenu } from 'core/dropdown';
import Link from 'next/link';
import { Button } from 'core/button';
import { Icon } from 'core/icon/icon';
import useScrollDirection from 'utils/hooks/scroll-direction.hook';
import { environment } from 'config/environment';

export const TopMenu = (props: TopMenuProps) => {
  const { color, transparent } = Object.assign({}, TopMenuPresets, props);

  const direction = useScrollDirection({
    thresholdPixels: 20,
    initialDirection: 'up',
  });

  const menu = (
    <DropdownMenu width="15.8rem">
      <DropdownItem to="/about">
        <Typography tx="shopMenu.ourStory" color="grey" />
      </DropdownItem>

      <DropdownItem to="/materials">
        <Typography tx="shopMenu.materials" color="grey" />
      </DropdownItem>

      <DropdownItem to="/factory">
        <Typography tx="shopMenu.factory" color="grey" />
      </DropdownItem>

      <DropdownItem to={environment.CREATE_HUB_URL}>
        <Typography tx="shopMenu.create" color="grey" />
      </DropdownItem>

      <DropdownItem to="/press">
        <Typography tx="shopMenu.press" color="grey" />
      </DropdownItem>
    </DropdownMenu>
  );

  return (
    <StyledTopMenu visible={direction === 'up'} transparent={transparent}>
      <Dropdown menu={menu}>
        <FlexContainer alignItems="center">
          <Typography tx="shopMenu.about" variant="p3" color={color} />
          <StyledAccountChevron name="chevron-down" color={color} size="1rem" />
        </FlexContainer>
      </Dropdown>

      <Link href="/refer">
        <a>
          <Typography tx="shopMenu.earn20" variant="p3" color={color} />
        </a>
      </Link>

      <a href="https://support.gantri.com/" target="_blank">
        <Typography tx="shopMenu.help" variant="p3" color={color} />
      </a>
    </StyledTopMenu>
  );
};
